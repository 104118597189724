exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n.EUvcssrc-containers-RulesView--styles {\n  text-align: center !important;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "EUvcssrc-containers-RulesView--styles"
};