exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._1k_LSsrc-containers-App-layouts-NormalLayoutRoute-components-Footer--styles .add-btn {\n  margin-top: -60px;\n}\n\n._1k_LSsrc-containers-App-layouts-NormalLayoutRoute-components-Footer--styles .container {\n  padding-bottom: 0px;\n}\n\n._1k_LSsrc-containers-App-layouts-NormalLayoutRoute-components-Footer--styles .bottom-navigation {\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);\n}\n\n@media (min-width: 1100px) {\n  ._1k_LSsrc-containers-App-layouts-NormalLayoutRoute-components-Footer--styles .bottom-navigation {\n    text-align: right;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_1k_LSsrc-containers-App-layouts-NormalLayoutRoute-components-Footer--styles"
};