exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._5G5R8src-containers-LatestUploadsView-UndefeatedPanel-Photo3--styles .image-container {\n  position: relative;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 30px;\n  width: 50%;\n  height: 300px;\n  width: 260px;\n  text-align: center;\n  align-self: center !important;\n}\n\n._5G5R8src-containers-LatestUploadsView-UndefeatedPanel-Photo3--styles .image-container .image-preview {\n  height: 260px;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n}\n\n._5G5R8src-containers-LatestUploadsView-UndefeatedPanel-Photo3--styles .image-container img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n@media (max-width: 400px) {\n  ._5G5R8src-containers-LatestUploadsView-UndefeatedPanel-Photo3--styles .image-container {\n    width: 100%;\n    margin: 0;\n    float: none;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_5G5R8src-containers-LatestUploadsView-UndefeatedPanel-Photo3--styles"
};