exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._3-7wzsrc-components-BottomNavigation--styles .bottom-navigation {\n  position: fixed;\n  bottom: 0px;\n  width: 100%;\n  height: 50px;\n  background: #f4f3ef;\n  text-align: center;\n  z-index: 1;\n}\n\n._3-7wzsrc-components-BottomNavigation--styles .transparent {\n  background: transparent;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_3-7wzsrc-components-BottomNavigation--styles"
};