import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Paper }            from '@material-ui/core'
import KingAnt              from 'assets/svgs/undefeatedkings/KingAnt.png'
import KingSam              from 'assets/svgs/undefeatedkings/KingSam.png'
import KingVela             from 'assets/svgs/undefeatedkings/KingVela.png'


import ProgressIndicator    from 'components/ProgressIndicator'
import { getString }        from 'core/libs/lib-asset-helpers'
import { styles }           from './styles.scss'

class Photo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainImage: null,
      imageContainer: <ProgressIndicator
        type="circle"
        size={60}
        thickness={6}
      />
    }
  }


  // Generate a number between 0 and 10, including 10
  generateRandomInteger(max) {
  return(Math.floor(Math.random() * max) + 1);
}

  componentDidMount() {

    let img=this.generateRandomInteger(13)
    this.setImage(img)
    this.showImage()
  }

  setImage=(img) => {
    switch (0) {
      case 0:
        this.setState({
          mainImage: <img className="placholder-image" src={KingVela} alt="Placeholder" />
        })
        break
                   
      default:
        break
    }
    
  }

  showImage=() => {
    setTimeout(() => {
      const { mainImage } = this.state
      this.setState({ imageContainer: mainImage })
    }, 500)
  }

  render() {
    const { imageContainer } = this.state

    return (
      <div className={styles}>
        <div className="image-container">
          <Paper>
            <div className="image-preview">
              {imageContainer}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default Photo
