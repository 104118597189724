exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._3ehmksrc-containers-RegisterView-layouts-DesktopView-components-Controls--styles .button-controls {\n  position: absolute;\n  bottom: -50px;\n  right: 0px;\n  width: 100%;\n}\n\n._3ehmksrc-containers-RegisterView-layouts-DesktopView-components-Controls--styles .btn {\n  float: right;\n  display: inline-block;\n  margin-left: 10px;\n  position: relative;\n  top: 10px;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_3ehmksrc-containers-RegisterView-layouts-DesktopView-components-Controls--styles"
};