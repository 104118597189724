import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { connect }                 from 'react-redux'
import { bindActionCreators }      from 'redux'
import { withRouter }              from 'react-router-dom'
import { Form, Label, Input }      from 'components/Form'
import * as accountActionCreators  from 'core/actions/actions-account'
import * as contractActionCreators from 'core/actions/actions-contract'
import { requestAccountAccess }    from 'core/libs/lib-metamask-helper'
// import Controls                    from '../../components/Controls'
import { styles }                  from './styles.scss'
import { Button } from '@material-ui/core'

class KingWithdrawPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allowToProceed: false,
      kname:'',
      eAmount:0,
      opCrown:0,
      nextBtnDisabled: true,
      id: ''
    }
  }

  componentDidMount() {
    const { actions } = this.props
   

    requestAccountAccess((defaultAccount) => {
      actions.account.setDefaultAccount(defaultAccount)
      actions.contract.setContract(defaultAccount)
      // actions.account.setName(actions.account.checkNameCrown())
    })
    
  }

  static getDerivedStateFromProps(nextProps) {
    const { account } = nextProps

    return { id: account.id }
  }

  onEnter = (evt) => {
    if (evt.key === 'Enter') {
      const { allowToProceed } = this.state
      if (allowToProceed) { this.proceed() }
    }
  }

  proceed = () => {
    const { actions, history } = this.props
    const { kname, eAmount, opCrown} = this.state
    
    actions.account.setopCrown(opCrown)
    actions.account.seteAmount(eAmount)
    actions.account.setName(kname)
    history.push('/register?panel=2')
  }

  enableNext=(input) => {
    const { opCrown, eAmount} = this.state
    if (input.valid) {
      
      this.setState({
        allowToProceed: true,
        kname: input.value
      })

      if (eAmount != 0){
        if(opCrown!=0){
          this.setState({
            nextBtnDisabled: false
          })
        }
      }
    }
      if (input.value==''){
        this.setState({
          nextBtnDisabled: true
        })
      }    
  }

  enableNext2=(input) => {
    

    const { kname, opCrown} = this.state

    if (input.valid) {
      this.setState({
        allowToProceed: true,
        eAmount: input.value
      })
      if (kname != "") {
        if(opCrown!=0){
          this.setState({
            nextBtnDisabled: false
          })
        }
      }
    }
    if (input.value==''){
      this.setState({
        nextBtnDisabled: true
      })
    }
  }

  enableNext3=(input) => {
    

    const { kname, eAmount} = this.state

    if (input.valid) {
      this.setState({
        allowToProceed: true,
        opCrown: input.value
      })
      if (kname != "") {
        if(eAmount!=0){
          this.setState({
            nextBtnDisabled: false
          })
        }
      }
    }
    if (input.value==''){
      this.setState({
        nextBtnDisabled: true
      })
    }
  }

  getPrice=() => { 
    const { actions, history } = this.props

    actions.account.checkAntCrownPrice().then((response) => alert("Last Price was: "+response/1000000000000000000 + "eth ! Now you have to guess how much was the increment the current King set"))
  }

  getName=() => { 
    const { actions, history } = this.props
    
    const jeje=actions.account.checkNameCrown().then((response) => alert("Last Name was: "+response))
    // debugger
    // this.getPrice()
    return jeje
  }

  getFunds=() => { 
    const { actions, history } = this.props

    actions.account.withDrawKing()
  }

  render() {
    const { id, nextBtnDisabled } = this.state
    const { actions, history } = this.props
    // debugger
    // let price= actions.account.checkNameCrown()
    
    // debugger
    return (
      <div className={styles}>
        <h2>Withdraw Jackpot</h2>
        <span>The Undefeated King has to be awarded</span>
        <Form>
        <div className="form-section">
            {/* <Label text="King Name" />
            <Input
              type="text"
              //autoFocus
              onKeyPress={this.onEnter}
              checkIfValid={this.enableNext}
              
            /> */}
          </div>
          <div className="form-section">
            <Label text=" · Click the button and you will be awarded if you are the truly Undefeated King" />
            <Button
              color="primary"
              onClick={this.getFunds}
              variant="outlined"
            >
              Claim Reward
            </Button>
            {/* <Input
              type="number"
              // autoFocus
              onKeyPress={this.onEnter}
              checkIfValid={this.enableNext2}
            /> */}
          </div>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      account: bindActionCreators(accountActionCreators, dispatch),
      contract: bindActionCreators(contractActionCreators, dispatch)
    }
  }
}

KingWithdrawPanel.propTypes = {
  account: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KingWithdrawPanel))
