import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import Stepper              from 'components/Steppers'
import Photo                from './components/Photo'
import Photo2                from './components/Photo2'
import Photo3                from './components/Photo3'
import Photo4                from './components/Photo4'
import CredentialsPanel     from './panels/CredentialsPanel'
import GenerateHashPanel    from './panels/GenerateHashPanel'
import RegisterAssetPanel   from './panels/RegisterAssetPanel'
import SuccessPanel         from './panels/SuccessPanel'
import NOSuccessPanel         from './panels/NOSuccessPanel'
import { styles }           from './styles.scss'

class DesktopView extends Component {
  renderContent() {
    const { panel } = this.props

    switch (panel) {
      case 1:
        return <CredentialsPanel />
      case 2:
        return <RegisterAssetPanel />
      case 3:
        return <SuccessPanel />
      case 4:
        return <NOSuccessPanel />
      default:
        break
    }

    return null
  }
  renderPhoto() {
    const { panel } = this.props
    switch (panel) {
      case 1:
        return <Photo/>
      case 2:
        return <Photo2 />
      case 3:
        return <Photo3 />
      case 4:
        return <Photo4 />
      default:
        break
    }

    return null
  }

  render() {
    const { panel } = this.props

    return (
      <div className={styles}>
        <div id="register-view">
          {this.renderPhoto()}
          {/* <Photo asset={asset} panel={panel} /> */}
          <div id="registration-form-container">
            <Stepper
              activeStep={panel - 1}
              steps={[
                'Enter Credentials',
                'Fight',
                'Result'
              ]}
            />
            <div id="registration-form">{this.renderContent()}</div>
          </div>
        </div>
      </div>
    )
  }
}

DesktopView.propTypes = {
  panel: PropTypes.number.isRequired
}

export default DesktopView
