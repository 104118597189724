exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2-dhSsrc-containers-RegisterView-layouts-DesktopView-panels-GenerateHashPanel--styles #unique-hash {\n  font-size: 36px;\n  word-break: break-all;\n  width: 95%;\n  margin: 0 auto;\n  margin-top: 40px;\n}\n\n._2-dhSsrc-containers-RegisterView-layouts-DesktopView-panels-GenerateHashPanel--styles #hash-progress-indicator {\n  margin-top: 100px;\n  text-align: center;\n}\n\n._2-dhSsrc-containers-RegisterView-layouts-DesktopView-panels-GenerateHashPanel--styles #hash-progress-indicator span {\n  display: block;\n  font-size: 20px;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2-dhSsrc-containers-RegisterView-layouts-DesktopView-panels-GenerateHashPanel--styles"
};