exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles {\n  margin: 140px  auto;\n  max-width: 1170px;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container {\n  position: relative;\n  display: inline-block;\n  float: left;\n  margin-top: -20px;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container svg {\n  zoom: 1.6;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container {\n  width: 65%;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container #registration-form {\n  display: flex;\n  flex-direction: column;\n  padding: 0px 20px 20px 20px;\n  background-color: #f4f3ef;\n  height: 70%;\n  border-radius: 5px;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container #registration-form h2 {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n._39npKsrc-containers-RegisterView-layouts-MobileView--styles #registration-form-container #registration-form form {\n  margin-top: 30px;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_39npKsrc-containers-RegisterView-layouts-MobileView--styles"
};