exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._3HPVTsrc-containers-App-components-MetaMaskNotification--styles .metamask-logo {\n  max-width: 190px;\n  float: left;\n  margin: 0 20px 20px 20px;\n}\n\n._3HPVTsrc-containers-App-components-MetaMaskNotification--styles .install-metamask-btn {\n  color: white;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_3HPVTsrc-containers-App-components-MetaMaskNotification--styles"
};