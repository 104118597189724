exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2FlMMsrc-containers-App-layouts-NormalLayoutRoute-components-Header-components-Navigation--styles .main-navigation {\n  background: #f4f3ef;\n}\n\n._2FlMMsrc-containers-App-layouts-NormalLayoutRoute-components-Header-components-Navigation--styles .main-navigation .tab {\n  padding-top: 11px;\n  color: #2d2e31;\n  font-weight: bold;\n}\n\n@media (min-width: 1100px) {\n  ._2FlMMsrc-containers-App-layouts-NormalLayoutRoute-components-Header-components-Navigation--styles {\n    padding: 0 25%;\n    margin-top: -72px;\n  }\n\n  ._2FlMMsrc-containers-App-layouts-NormalLayoutRoute-components-Header-components-Navigation--styles div:first-child {\n    box-shadow: none;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2FlMMsrc-containers-App-layouts-NormalLayoutRoute-components-Header-components-Navigation--styles"
};