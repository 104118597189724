import React, { Component }     from 'react'
import PropTypes                from 'prop-types'
import { connect }              from 'react-redux'
import { bindActionCreators }   from 'redux'
import { withRouter }           from 'react-router-dom'
import * as assetActionCreators from 'core/actions/actions-asset'
import Controls                 from '../../components/Controls'
import { styles }               from './styles.scss'

class RegisterAssetPanel extends Component {
  constructor(props) {
    super(props)
    const { eAmount,kname, id, opCrown } = props.account
    const { assetHash } = props.asset

    this.state = {
      nextBtnDisabled: !((kname && id && eAmount && opCrown))
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.asset.success) {
      const { history } = this.props
      history.push('/register?panel=3')
    }else{
      const { history } = this.props
      history.push('/register?panel=4')
    }
  }

  registerAsset = () => {
    const { actions } = this.props
    actions.asset.register()
  }

  render() {
    const { id, kname, eAmount, opCrown } = this.props.account
    // const { assetHash } = this.props.asset
    const { nextBtnDisabled } = this.state

    return (
      <div className={styles}>
        <h2>Confirm Transaction</h2>
        <span>Summary of your information. </span>
        <p><span>The transaction may take a while to be mined...be patient plz</span></p>
        <div id="registration-details">
          <ul>
            <li>
              <span>Your Name:</span>
              <span>{kname}</span>
            </li>
            <li>
              <span>Your Wallet:</span>
              <span>{id}</span>
            </li>
            <li>
              <span>Your bet for <br />the Throne:</span>
              <span>{eAmount} eth</span>
            </li>
            <li>
              <span>The price for the <br />crown will be increased %:</span>
              <span>{opCrown}</span>
            </li>
          </ul>
        </div>
        <Controls
          prevDisabled={false}
          nextDisabled={nextBtnDisabled}
          nextLabel="FIGHT"
          handleNext={this.registerAsset}
        />
      </div>
    )
  }
}

RegisterAssetPanel.propTypes = {
  account: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({}).isRequired,
  asset: PropTypes.shape({
    assetHash: PropTypes.string,
    transaction: PropTypes.shape({})
  }),
  history: PropTypes.shape({}).isRequired
}

RegisterAssetPanel.defaultProps = {
  asset: null
}

function mapStateToProps(state) {
  return {
    account: state.account,
    asset: state.asset
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      asset: bindActionCreators(assetActionCreators, dispatch)
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterAssetPanel))
