exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._1BPUtsrc-components-Modals-StandardModal--styles .dialog-title,\n._1BPUtsrc-components-Modals-StandardModal--styles .dialog-content {\n  padding: 0px;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_1BPUtsrc-components-Modals-StandardModal--styles"
};