import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { styles }           from './styles.scss'
import Controls                    from '../../components/Controls'

class SuccessPanel extends Component {
  render() {
    return (
      <div className={styles}>
        <div className="notification">
          <h2>CONGRATULATIONS!</h2>
          <h3> You overThroned the KING! Your reign starts here! </h3>
          <span></span>
          {/* <span className="action"><Link to="/pending">See your pending transaction</Link></span> */}
        </div>

        <Controls
          prevDisabled={true}
          nextDisabled={false}
          nextLabel="BACK TO THE LOBBY"
          // handleNext={}
        />
      </div>
      
    )
  }
}

export default withRouter(SuccessPanel)
