import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { connect }                 from 'react-redux'
import { bindActionCreators }      from 'redux'
import { withRouter }              from 'react-router-dom'
import { Form, Label, Input }      from 'components/Form'
import * as accountActionCreators  from 'core/actions/actions-account'
import * as contractActionCreators from 'core/actions/actions-contract'
import { requestAccountAccess }    from 'core/libs/lib-metamask-helper'
// import Controls                    from '../../components/Controls'
import { styles }                  from './styles.scss'
import { Button } from '@material-ui/core'

class RulesPanel extends Component {


  
  render() {

    // debugger
    // let price=actions.account.checkAntCrownPrice()

    return (
      <div className={styles}>
        <h2>Game Rules</h2>
        <span>You need to follow the rules if you want to try to overthrone the current King</span>
        <Form>
        <div className="form-section">
            {/* <Label text="King Name" />
            <Input
              type="text"
              //autoFocus
              onKeyPress={this.onEnter}
              checkIfValid={this.enableNext}
              
            /> */}
          </div>
          <div className="form-section">
            <Label text=" · You will overthrone the king if your bet is bigger than the current price of the crown" />
            {/* <Button
              color="primary"
              onClick={this.getPrice}
              variant="outlined"
            >
              HINT
            </Button> */}
            {/* <Input
              type="number"
              // autoFocus
              onKeyPress={this.onEnter}
              checkIfValid={this.enableNext2}
            /> */}
          </div>
          <div className="form-section">
            <Label text=" · You can make money selling your crown for more money than you bought it...but if no candidates appear maybe you will loose everything!" />
            {/* <Input
              type="number"
              // autoFocus
              onKeyPress={this.onEnter}
              checkIfValid={this.enableNext3}
            /> */}
          </div>
          <div className="form-section">
            <Label text="· The last King will get the jackpot...or not!" />
            {/* <Input
              type="text"
              readOnly
              value={id}
            /> */}
          </div>
        </Form>
      </div>
    )
  }
}





export default RulesPanel
