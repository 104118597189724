exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._3f8IPsrc-containers-RegisterView-layouts-DesktopView-components-Photo--styles .image-container {\n  position: relative;\n  float: left;\n  height: 100%;\n  width: 360px;\n  margin-right: 30px;\n  text-align: center;\n}\n\n._3f8IPsrc-containers-RegisterView-layouts-DesktopView-components-Photo--styles .image-container .image-preview {\n  height: 360px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n}\n\n._3f8IPsrc-containers-RegisterView-layouts-DesktopView-components-Photo--styles .image-container img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n@media (max-width: 400px) {\n  ._3f8IPsrc-containers-RegisterView-layouts-DesktopView-components-Photo--styles .image-container {\n    width: 100%;\n    margin: 0;\n    float: none;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_3f8IPsrc-containers-RegisterView-layouts-DesktopView-components-Photo--styles"
};