exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2bza0src-components-EmptyState--styles {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n  height: 87vh;\n}\n\n._2bza0src-components-EmptyState--styles .placeholder {\n  height: 170px;\n  width: 250px;\n  text-align: center;\n}\n\n._2bza0src-components-EmptyState--styles .icon {\n  margin-top: 20px;\n}\n\n._2bza0src-components-EmptyState--styles .icon svg {\n  font-size: 350%;\n  opacity: 0.1;\n}\n\n@media (max-width: 600px) {\n  ._2bza0src-components-EmptyState--styles .placeholder {\n    margin-top: 40px;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2bza0src-components-EmptyState--styles"
};