exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2wT49src-components-AppBar--styles .app-bar {\n  background: #f4f3ef;\n  color: #2d2e31;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2wT49src-components-AppBar--styles"
};