exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2El38src-containers-LatestUploadsView--styles {\n  padding-top: 130px;\n  text-align: center !important;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2El38src-containers-LatestUploadsView--styles"
};