exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2-S6nsrc-components-Steppers--styles .root.disabled svg circle {\n  fill: #9e9e9e;\n}\n\n._2-S6nsrc-components-Steppers--styles .root svg text {\n  fill: white;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2-S6nsrc-components-Steppers--styles"
};