import constants from 'core/types'
import sha256    from 'sha256'

export function addAsset(asset) {
  return {
    type: constants.ADD_ASSET,
    asset
  }
}

export function clear() {
  return {
    type: constants.CLEAR_ASSETS
  }
}

async function checkIfAssetRegistered(ProofOfExContract, assetHash) {
  const assetExists = ProofOfExContract.deployed().then((poe) => {
    return poe.checkIfRegistered(assetHash)
  })
  return assetExists
}

async function overThroneKing(ProofOfExContract, eAmount, kname, opCrown) {

  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  return poe.otk(kname,opCrown,{ value: (eAmount*1000000000000000000) })
  })
  // const transaction = (result !== null) ? result : null
  return result
  
  
}


async function checkKing(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.chkKing()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}


async function checkKingName(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.chkKingName()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}

async function checkCPrice(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.chkCrownPrice()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}

async function overThroned(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.over()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}


function dispatchAssetAlreadyExists(dispatch) {
  dispatch((() => {
    return {
      type: constants.CHECK_ASSET,
      alreadyExists: true
    }
  })())
}

function dispatchAssetDoesNotExist(assetHash, dispatch) {
  dispatch((() => {
    return {
      type: constants.CHECK_ASSET,
      alreadyExists: false,
      assetHash
    }
  })())
}

function dispatchCrowned(assetHash,dispatch) {
  dispatch((() => {
    return {
      type: constants.CREATE_ASSET_HASH,
      success: true,
      assetHash
    }
  })())
}

function dispatchDefeated(assetHash,dispatch) {
  dispatch((() => {
    // console.log("aquiiii: " + assetHash)
    return {
      type: constants.CREATE_ASSET_HASH,
      success: false,
      assetHash
    }
  })())
}

export function checkIfRegistered(assetUrl) {
  return async (dispatch, getState) => {
    const { proofOfExContract } = getState().contract
    const assetHash = sha256(assetUrl)
    const assetExists = await checkIfAssetRegistered(proofOfExContract, assetHash)

    if (assetExists) {
      dispatchAssetAlreadyExists(dispatch)
    } else {
      dispatchAssetDoesNotExist(assetHash, dispatch)
    }
  }
}

export function register() {
  return async (dispatch, getState) => {

    const { proofOfExContract } = getState().contract
    const { eAmount, kname, opCrown } = getState().account
    console.log("eAmount: "+eAmount)
    const transaction = await overThroneKing(proofOfExContract, eAmount, kname, opCrown)
    console.log(transaction)

    const whoKingName = await checkKingName(proofOfExContract)
    console.log("whoKingName: "+ whoKingName)
    const assetHash =whoKingName

    const cPrice = await checkCPrice(proofOfExContract)
    console.log("checkCPrice: "+ cPrice)

  
    const overt = await overThroned(proofOfExContract)
    console.log("overThroned: "+ overt)


    if (overt) {
      dispatchCrowned(assetHash, dispatch)
     } else {
      dispatchDefeated(assetHash, dispatch)
     }
  }
}


export function chKing() {
  
  return async (dispatch, getState) => {
    
    const { proofOfExContract } = getState().contract

    const whoKing = await checkKing(proofOfExContract)
    console.log("whoKing: "+ whoKing)
    

    return whoKing

    // if (overt) {
    //   dispatchCrowned(dispatch)
    //  } else {
    //   dispatchDefeated(dispatch)
    //  }
  }
}

export function chKingName() {
  
  return async (dispatch, getState) => {
   
    const { proofOfExContract } = getState().contract
    const whoKingName = await checkKingName(proofOfExContract)
    console.log("whoKingName: "+ whoKingName)
    

    return whoKingName

    // if (overt) {
    //   dispatchCrowned(dispatch)
    //  } else {
    //   dispatchDefeated(dispatch)
    //  }
  }
}