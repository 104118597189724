import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import { Paper }            from '@material-ui/core'
import defeated             from 'assets/svgs/defeated/defeated.gif'
import img0                 from 'assets/svgs/defeated/0.png'
import img1                 from 'assets/svgs/defeated/1.png'
import img2                 from 'assets/svgs/defeated/2.png'
import img3                 from 'assets/svgs/defeated/3.png'
import img4                 from 'assets/svgs/defeated/4.png'
import img5                 from 'assets/svgs/defeated/5.png'
import img6                 from 'assets/svgs/defeated/6.png'
import img7                 from 'assets/svgs/defeated/7.png'
import img8                 from 'assets/svgs/defeated/8.png'
import img9                 from 'assets/svgs/defeated/9.png'
import ProgressIndicator    from 'components/ProgressIndicator'
import { getString }        from 'core/libs/lib-asset-helpers'
import { styles }           from './styles.scss'

class Photo4 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainImage: null,
      imageContainer: <ProgressIndicator
        type="circle"
        size={60}
        thickness={6}
      />
    }
  }

  // Generate a number between 0 and 10, including 10
  generateRandomInteger(max) {
    return(Math.floor(Math.random() * max) + 1);
  }
  
  componentDidMount() {
    let img=this.generateRandomInteger(11)
    this.setImage(img)
    this.showImage()
  }

  setImage=(img) => {
    switch (img) {
      case 0:
        this.setState({
          mainImage: <img className="placholder-image" src={img0} alt="Placeholder" />
        })
        break
      case 1:
        this.setState({
          mainImage: <img className="placholder-image" src={img1} alt="Placeholder" />
        })
        break
      case 2:
        this.setState({
          mainImage: <img className="placholder-image" src={img2} alt="Placeholder" />
        })
        break
      case 3:
        this.setState({
          mainImage: <img className="placholder-image" src={img3} alt="Placeholder" />
        })
        break
      case 4:
        this.setState({
          mainImage: <img className="placholder-image" src={img4} alt="Placeholder" />
        })
        break
      case 5:
        this.setState({
          mainImage: <img className="placholder-image" src={img5} alt="Placeholder" />
        })
        break
      case 6:
        this.setState({
          mainImage: <img className="placholder-image" src={img6} alt="Placeholder" />
        })
        break
      case 7:
        this.setState({
          mainImage: <img className="placholder-image" src={img7} alt="Placeholder" />
        })
        break
      case 8:
        this.setState({
          mainImage: <img className="placholder-image" src={img8} alt="Placeholder" />
        })
        break
      case 9:
        this.setState({
          mainImage: <img className="placholder-image" src={img9} alt="Placeholder" />
        })
        break


              
      default:
        break
    }
    
  }

  showImage=() => {
    setTimeout(() => {
      const { mainImage } = this.state
      this.setState({ imageContainer: mainImage })
    }, 500)
  }

  render() {
    const { imageContainer } = this.state

    return (
      <div className={styles}>
        <div className="image-container">
          <Paper>
            <div className="image-preview">
              {imageContainer}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default Photo4
