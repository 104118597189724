exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details {\n  margin: 0 auto;\n  margin-top: 30px;\n}\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li {\n  padding: 10px;\n  text-align: left;\n}\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li span:nth-child(1) {\n  display: inline-block;\n  width: 140px;\n}\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li span:nth-child(2) {\n  text-align: right;\n  max-width: 340px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  word-break: break-word;\n}\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li:first-child,\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li:last-child {\n  border-top: 1px dotted #505050;\n}\n\n.HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles #registration-details li:first-child {\n  font-weight: bold;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "HIMMOsrc-containers-RegisterView-layouts-DesktopView-panels-RegisterAssetPanel--styles"
};