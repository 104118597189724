import React, { Component } from 'react'
import Description          from '@material-ui/icons/Description'
import EmptyState           from 'components/EmptyState'
import { styles }           from './styles.scss'
import KingWithdrawPanel     from './KingWithdrawPanel'

class KingWithdrawView extends Component {

  renderContent() {
    const { panel } = this.props

    switch (1) {
      case 1:
        return <KingWithdrawPanel />
      default:
        break
    }

    return null
  }

  render() {
    return (
      <div className={styles}>
        {/* <EmptyState
          message="Undefeated Kings"
          icon={<AllInclusive />}
        /> */}
      <div id="registration-form">{this.renderContent()}</div>
      </div>
    )
  }
}

export default KingWithdrawView




