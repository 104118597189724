exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2Pegbsrc-containers-RegisterView-layouts-MobileView-panels-CredentialsPanel--styles .form-section {\n  display: inline-block;\n  width: 100%;\n}\n\n._2Pegbsrc-containers-RegisterView-layouts-MobileView-panels-CredentialsPanel--styles .form-section input[type=\"text\"]:disabled {\n  opacity: 0.6;\n}\n\n._2Pegbsrc-containers-RegisterView-layouts-MobileView-panels-CredentialsPanel--styles .form-section input {\n  width: 97%;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2Pegbsrc-containers-RegisterView-layouts-MobileView-panels-CredentialsPanel--styles"
};