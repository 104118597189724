exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2DU1Xsrc-containers-LatestUploadsView-UndefeatedPanel--styles h2 {\n  margin-bottom: 25px;\n  text-decoration: underline;\n}\n\n._2DU1Xsrc-containers-LatestUploadsView-UndefeatedPanel--styles .form-section {\n  text-align: center;\n}\n\n._2DU1Xsrc-containers-LatestUploadsView-UndefeatedPanel--styles .form-section input[type=\"text\"]:disabled {\n  opacity: 0.6;\n}\n\n._2DU1Xsrc-containers-LatestUploadsView-UndefeatedPanel--styles .form-section input {\n  width: 70%;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2DU1Xsrc-containers-LatestUploadsView-UndefeatedPanel--styles"
};