exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n.uHHbQsrc-containers-RulesView-RulesPanel--styles {\n  padding-top: 130px;\n}\n\n.uHHbQsrc-containers-RulesView-RulesPanel--styles h2 {\n  text-decoration: underline;\n}\n\n.uHHbQsrc-containers-RulesView-RulesPanel--styles .form-section {\n  display: inline-block;\n  text-align: center;\n  width: 70%;\n  padding-top: 30px;\n}\n\n.uHHbQsrc-containers-RulesView-RulesPanel--styles .form-section input[type=\"text\"]:disabled {\n  opacity: 0.6;\n}\n\n.uHHbQsrc-containers-RulesView-RulesPanel--styles .form-section input {\n  width: 70%;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "uHHbQsrc-containers-RulesView-RulesPanel--styles"
};