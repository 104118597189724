import constants from 'core/types'

const initialState = {
  email: '',
  kname:'',
  eAmount:0,
  id: ''
}

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_ACCOUNT:
      return Object.assign({}, state, {
        id: action.id
      })

    case constants.SET_ACCOUNT_EMAIL:
      return Object.assign({}, state, {
        email: action.email
      })
    case constants.SET_ACCOUNT_KNAME:
        return Object.assign({}, state, {
          kname: action.kname
        })
    case constants.SET_ACCOUNT_EAMOUNT:
        return Object.assign({}, state, {
          eAmount: action.eAmount
        })
    case constants.SET_ACCOUNT_OPCROWN:
        return Object.assign({}, state, {
          opCrown: action.opCrown
        })
    case constants.CLEAR_ACCOUNT:
      return Object.assign({}, state, {
        email: '',
        kname: '',
        eAmount:0
      })

    default:
      return state
  }
}
