exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n.v2k6gsrc-components-UploadBox--styles .upload-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 405px;\n  background: white;\n}\n\n.v2k6gsrc-components-UploadBox--styles .upload-container.drop {\n  border: 3px dashed #505050;\n}\n\n.v2k6gsrc-components-UploadBox--styles .helper-text {\n  width: 220px;\n  display: inline-block;\n  margin-top: 20px;\n}\n\n.v2k6gsrc-components-UploadBox--styles .upload-actions {\n  text-align: center;\n}\n\n.v2k6gsrc-components-UploadBox--styles .upload-actions #file-list {\n  display: inline-block;\n}\n\n.v2k6gsrc-components-UploadBox--styles .upload-icon {\n  font-size: 150px;\n  color: #2d2e31;\n}\n\n.v2k6gsrc-components-UploadBox--styles .upload-btn {\n  color: white;\n  margin-bottom: 10px;\n}\n\n.v2k6gsrc-components-UploadBox--styles input[name=\"myFile\"] {\n  display: none;\n}\n\n@media (max-width: 600px) {\n  .v2k6gsrc-components-UploadBox--styles .upload-container {\n    height: 515px;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "v2k6gsrc-components-UploadBox--styles"
};