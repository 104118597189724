exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._3VA1isrc-containers-App-layouts-NormalLayoutRoute--styles .normal-layout {\n  margin-top: 65px;\n  height: 86vh;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_3VA1isrc-containers-App-layouts-NormalLayoutRoute--styles"
};