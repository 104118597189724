exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2Ur_Asrc-components-Modals-ConfirmationModal--styles .dialog-title,\n._2Ur_Asrc-components-Modals-ConfirmationModal--styles .dialog-content {\n  padding: 0px;\n}\n\n@media (min-width: 900px) {\n  ._2Ur_Asrc-components-Modals-ConfirmationModal--styles {\n    width: 400px;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2Ur_Asrc-components-Modals-ConfirmationModal--styles"
};