exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._37WZbsrc-components-Form--labelStyles label {\n  font-weight: bold;\n  font-size: 14px;\n  margin-bottom: 10px;\n  display: block;\n}\n\n._2tDuusrc-components-Form--inputStyles input {\n  margin-bottom: 20px;\n  width: 100%;\n  padding: 10px;\n  height: 10px;\n}\n\n", ""]);

// exports
exports.locals = {
	"labelStyles": "_37WZbsrc-components-Form--labelStyles",
	"inputStyles": "_2tDuusrc-components-Form--inputStyles"
};