exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._-7nwmsrc-components-Button--styles button {\n  color: white;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_-7nwmsrc-components-Button--styles"
};