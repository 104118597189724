import constants from 'core/types'

export function setEmail(email) {
  return {
    type: constants.SET_ACCOUNT_EMAIL,
    email
  }
}

export function setName(kname) {
  return {
    type: constants.SET_ACCOUNT_KNAME,
    kname
  }
}
export function seteAmount(eAmount) {
  return {
    type: constants.SET_ACCOUNT_EAMOUNT,
    eAmount
  }
}
export function setopCrown(opCrown) {
  return {
    type: constants.SET_ACCOUNT_OPCROWN,
    opCrown
  }
}


async function checkAntCPrice(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.chkAntCrownPrice()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}

export function checkAntCrownPrice() {
  return async (dispatch, getState) => {

    const { proofOfExContract } = getState().contract
    const antcPrice = await checkAntCPrice(proofOfExContract)
    console.log("checkAntCPrice: "+ antcPrice)

    return antcPrice

  }
}



async function checkNameCrownSC(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.chkKingName()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}


export function checkNameCrown() {
  return async (dispatch, getState) => {
    // console.log("Name Crown")

    const { proofOfExContract } = getState().contract
  
    const namecrown = await checkNameCrownSC(proofOfExContract)
    console.log("Name: "+ namecrown)

    return namecrown

  }
}


async function withDrawKingSC(ProofOfExContract) {
  const result = ProofOfExContract.deployed().then((poe) => {
  //   return poe.deposit()
  // return poe.deposit({ value: (eAmount*1000000000000000000) })
  return poe.withdrawFunds()
  
  })
  const transaction = (result !== null) ? result : null
  return transaction
 
}


export function withDrawKing() {
  return async (dispatch, getState) => {
    console.log("withDrawKing")

    const { proofOfExContract } = getState().contract   
    const namecrown = await withDrawKingSC(proofOfExContract)
    console.log("Funds withdraw to the king: "+ namecrown)

    return namecrown

  }
}



export function clear() {
  return {
    type: constants.CLEAR_ACCOUNT
  }
}

export function setDefaultAccount(defaultAccount) {
  return (dispatch, getState) => {
    const { web3Provider } = getState().provider
    web3Provider.eth.defaultAccount = defaultAccount

    dispatch((() => {
      return {
        type: constants.SET_ACCOUNT,
        id: defaultAccount
      }
    })())
  }
}
