exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2wqTPsrc-containers-UploadView--modalStyles {\n  height: 100%;\n  box-shadow: none;\n  position: relative;\n  overflow: hidden;\n}\n\n._2wqTPsrc-containers-UploadView--modalStyles .bottom-navigation {\n  position: absolute;\n}\n\n._2wqTPsrc-containers-UploadView--modalStyles .arrow-icon {\n  margin-left: -10px;\n}\n\n._2wqTPsrc-containers-UploadView--modalStyles .bottom-section {\n  position: relative;\n  top: 50px;\n}\n\n@media (min-width: 900px) {\n  ._2wqTPsrc-containers-UploadView--modalStyles {\n    width: 600px;\n    height: 475px;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"modalStyles": "_2wqTPsrc-containers-UploadView--modalStyles"
};