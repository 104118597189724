exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n.xNUQjsrc-containers-RegisterView-layouts-MobileView-components-Controls--styles {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  bottom: 20px;\n  left: 20px;\n  width: 90%;\n}\n\n.xNUQjsrc-containers-RegisterView-layouts-MobileView-components-Controls--styles .button-controls,\n.xNUQjsrc-containers-RegisterView-layouts-MobileView-components-Controls--styles .btn {\n  width: 100%;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "xNUQjsrc-containers-RegisterView-layouts-MobileView-components-Controls--styles"
};