exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/******* Theme *******/\n\n/******* Colors *******/\n\n._2DNb-src-containers-RegisterView--styles .notification {\n  height: 100%;\n  height: 100%;\n}\n\n._2DNb-src-containers-RegisterView--styles .notification span.action {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 80px;\n  font-size: 22px;\n  font-weight: bold;\n}\n\n", ""]);

// exports
exports.locals = {
	"styles": "_2DNb-src-containers-RegisterView--styles"
};