import React, { Component }        from 'react'
import PropTypes                   from 'prop-types'
import { connect }                 from 'react-redux'
import { bindActionCreators }      from 'redux'
import { withRouter }              from 'react-router-dom'
import { Form, Label, Input }      from 'components/Form'
import * as accountActionCreators  from 'core/actions/actions-account'
import * as contractActionCreators from 'core/actions/actions-contract'
import { requestAccountAccess }    from 'core/libs/lib-metamask-helper'
// import Controls                    from '../../components/Controls'
import { styles }                  from './styles.scss'
import { Button } from '@material-ui/core'
import Photo                       from './Photo'
import Photo2                      from './Photo2'
import Photo3                      from './Photo3'

class UndefeatedPanel extends Component {


  render() {

    return (
      <div className={styles}>
        <h2>Undefeated Kings</h2>
        <h3>King Vela - 1.27Ξ</h3>
        <Photo/>
        <h3>King Sam - 1.79Ξ</h3>
        <Photo2/>
        <h3>King Ant - 2.12Ξ</h3>
        <Photo3/>

      </div>
    )
  }
}



export default UndefeatedPanel
